var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"background-color":"white"}},[_c('ASuccessWithoutReload',{attrs:{"api":this.api},on:{"close":_vm.closeSuccessModal}}),_c('AConfirmation',{attrs:{"isShow":_vm.isPending},on:{"cancel":_vm.cancelSubmit,"confirm":_vm.submit}}),(_vm.api.isLoading)?_c('v-skeleton-loader',{ref:"skeleton",attrs:{"type":"table"}}):_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"12"}},[_c('v-card',{staticClass:"py-4"},[_c('v-toolbar',{staticClass:"secondary ma-4"},[_c('v-toolbar-title',{staticClass:"white--text text-h4"},[_vm._v(" Add Subscription History ")])],1),_c('v-container',[_c('v-row',{staticClass:"mx-2"},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('div',{staticClass:"d-flex justify-start"},[_c('v-autocomplete',{attrs:{"items":_vm.optionRenewedStatus,"dense":"","loading":_vm.isAllLoading,"outlined":"","label":"Status"},model:{value:(_vm.data.status),callback:function ($$v) {_vm.$set(_vm.data, "status", $$v)},expression:"data.status"}})],1),_c('div',{staticClass:"d-flex justify-start"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Year (Required)"},model:{value:(_vm.data.year),callback:function ($$v) {_vm.$set(_vm.data, "year", $$v)},expression:"data.year"}})],1),_c('div',{staticClass:"d-flex justify-start"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Year(s) of Subscription"},model:{value:(_vm.data.subscription_year),callback:function ($$v) {_vm.$set(_vm.data, "subscription_year", $$v)},expression:"data.subscription_year"}})],1),_c('div',{staticClass:"d-flex justify-start"},[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","label":"Price (RM)","items":_vm.optionsPrice},model:{value:(_vm.data.price),callback:function ($$v) {_vm.$set(_vm.data, "price", $$v)},expression:"data.price"}})],1),_c('div',{},[_c('ComponentDateModal',{attrs:{"label":"Payment date","data":_vm.data.payment_date},on:{"updateDateData":function (data){
                                        this$1.data.payment_date = data;
                                    }}})],1),_c('v-row',[_c('v-col',[_c('ComponentDateModal',{attrs:{"label":"Start date","data":_vm.data.subscription_start_date},on:{"updateDateData":function (data){
                                            this$1.data.subscription_start_date = data;
                                        }}})],1),_c('v-col',[_c('ComponentDateModal',{attrs:{"label":"End date","data":_vm.data.subscription_end_date},on:{"updateDateData":function (data){
                                            this$1.data.subscription_end_date = data;
                                        }}})],1)],1),_c('div',{},[_c('ComponentDateModal',{attrs:{"label":"Commision release date","data":_vm.data.commision},on:{"updateDateData":function (data){
                                        this$1.data.commision = data;
                                    }}})],1),_c('div',[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","items":_vm.optionsSalesperson,"item-text":"salesperson","item-value":"salesperson","label":"Salesperson"},model:{value:(_vm.data.pic_salesperson),callback:function ($$v) {_vm.$set(_vm.data, "pic_salesperson", $$v)},expression:"data.pic_salesperson"}})],1),_c('div',[_c('v-autocomplete',{attrs:{"items":_vm.optiosnOffer,"label":"Offer","dense":"","outlined":""},model:{value:(_vm.data.offer),callback:function ($$v) {_vm.$set(_vm.data, "offer", $$v)},expression:"data.offer"}})],1),_c('div',[_c('v-autocomplete',{attrs:{"items":_vm.optionsCancellationReason,"label":"Cancellation reason","dense":"","outlined":""},model:{value:(_vm.data.cancellation_reason),callback:function ($$v) {_vm.$set(_vm.data, "cancellation_reason", $$v)},expression:"data.cancellation_reason"}})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.validateInput}},[_vm._v(" Submit ")])],1),_c('div',{staticClass:"mt-3"},[_c('AError',{attrs:{"api":this.api}})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }