<template>
    <v-container style="background-color:white">
        <AError :api="this.api" />
        <ASuccessWithoutReload :api="this.api" @close="closeSuccessModal" />
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit" />
        <v-skeleton-loader v-if="api.isLoading" ref="skeleton" type="table">
        </v-skeleton-loader>
        <v-row v-else>
            <v-col class="pa-0" cols="12" sm="12">
                <v-card class="py-4"
                outlined>
                    <v-toolbar class="secondary ma-4">
                        <v-toolbar-title class="white--text text-h4">
                            Add Funnel
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-container>   
                        <v-row class="mx-2">
                            <v-col cols="12" sm="12">
                                <div class="">
                                    <ComponentDateModal label="Funnel Event Date" :data="data.funnel_event_date"
                                        @updateDateData="(data) => {
                                            this.data.funnel_event_date = data;
                                        }" />
                                </div>
                                <div class="d-flex justify-start">
                                    <v-autocomplete :items="optionLeadSource" v-model="data.lead_source" dense outlined
                                        label="Lead Source">
                                    </v-autocomplete>
                                </div>
                                <div class="d-flex justify-end">
                                    <v-btn @click="closeModal" color="primary" plain>
                                        Cancel
                                    </v-btn>
                                    <v-btn @click="validateInput" color="primary">
                                        Submit
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ComponentDateModal from '../date/ComponentDateModal.vue';
import ASuccessWithoutReload from '../common/ASuccessWithoutReload.vue';
import AConfirmation from '../common/AConfirmation.vue';
export default {
    components: {
        AConfirmation,
        ComponentDateModal,
        ASuccessWithoutReload,
    },
    computed: mapState({
        //
    }),
    props: [
        'companyId'
    ],
    data: () => ({
        isPending: false,
        form: {
            lead_source: null,
            funnel_event_date: null,
        },
        optionLeadSource: [],
        data: {
            status: null,
        },
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        },

    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class === "createCompanyFunnelByCompany" || resp.class==='createCompanyFunnelBatch') {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully create company funnel";
            }
            if (resp.class === "getCompanyFunnel") {
                this.data = resp.data;
                this.data.funnel_event_date = (this.data.funnel_event_date != null) ? this.convertTimeToDay(this.data.funnel_event_date) : this.data.funnel_event_date;
            }
            if (resp.class === "getListOfDropdown") {
                this.optionLeadSource = resp.data.map(item => item.value);
            }
            if(resp.class==='getListOfCustomizeDropdown') {
                if(resp.type==='lead') {
                    this.optionLeadSource = resp.data.map(item => item.lead);
                }
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.$api.fetch(this.fetchOptionsLead());
        },
        fetchOptionsLead() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/dropdown/customize?type=lead";
            return tempApi;
        },

        validateInput() {
            this.isPending = true;
        },
        submit() {
            this.isPending = false;
            if(!Array.isArray(this.companyId)) {
                this.$api.fetch(this.create());
                return 1;
            }
            this.$api.fetch(this.createMultipleForm());
        },
        cancelSubmit() {
            this.isPending = false;
        },
        create() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/funnel/company/detail/" + this.companyId;
            tempApi.params = this.data;
            return tempApi;
        },
        createMultipleForm() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/funnel/batch";
            let formData = new FormData;
            this.companyId.forEach((element)=>{
                formData.append('companyId[]',element.company_id);
            });

            formData.append('lead_source',this.data.lead_source);
            formData.append('funnel_event_date',this.data.funnel_event_date);
            tempApi.params = formData;
            return tempApi;
        },
        updateDataPaymentDate(data) {
            this.form.subscriptionPaymentDate = data;
        },
        convertTimeToDay(time) {
            return time.split(" ")[0];
        },
        closeModal() {
            this.$emit("close")
        },
        closeSuccessModal() {
            this.api.isSuccesful = false;
            this.$emit('finish');
       },
    }
}
</script>