<template>
    <v-container
        style="background-color:white">
        <AError :api="this.api"/>
        <ASuccessWithoutReload
            :api="this.api"
            @close="closeSuccessModal"
        />
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit" />
        <v-skeleton-loader
            v-if="api.isLoading" ref="skeleton" type="table">
        </v-skeleton-loader>
        <v-row
            v-else>
            <v-col
                class="pa-0"
                cols="12"
                sm="12">
                <v-card
                    outlined
                    elevatio="1"
                    class="py-4">
                    <v-toolbar
                        class="secondary ma-4">
                        <v-toolbar-title
                            class="white--text text-h4">
                            Add HRDC
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-container>
                        <v-row
                            class="mx-2">
                            <v-col
                            cols="12"
                            sm="12">
                                <div
                                    class="d-flex justify-start">
                                    <v-text-field
                                        v-model="data.hrdc_invoice"
                                        dense 
                                        outlined
                                        label="HRDC Invoice">
                                    </v-text-field>
                                </div>
                                <!-- <div
                                    class="d-flex justify-start">
                                    <v-autocomplete
                                        :items="optionHRDCStatus"
                                        v-model="data.status"
                                        dense 
                                        outlined
                                        label="Status">
                                    </v-autocomplete>
                                </div> -->
                                <div
                                    class="d-flex justify-start">
                                    <v-text-field
                                        v-model="data.year"
                                        dense 
                                        outlined
                                        label="Year">
                                    </v-text-field>
                                </div>
                                <div
                                    class="d-flex justify-start">
                                    <v-text-field
                                        v-model="data.year_of_subscription"
                                        dense 
                                        outlined
                                        label="Year(s) of Subscription">
                                    </v-text-field>
                                </div>
                                <div
                                    class="">
                                    <ComponentDateModal
                                        label="HRDC Invoice Sent Date"
                                        :data="data.hrdc_invoice_sent_date"
                                        @updateDateData="(data)=>{
                                            this.data.hrdc_invoice_sent_date = data;
                                        }"/>
                                </div>
                                <div
                                    class="d-flex justify-end">
                                    <v-btn
                                        @click="closeModal"
                                        color="primary"
                                        plain>
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        @click="validateInput"
                                        color="primary">
                                        Submit
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ComponentDateModal from '../date/ComponentDateModal.vue';
import ASuccessWithoutReload from '../common/ASuccessWithoutReload.vue';
import AConfirmation from '../common/AConfirmation.vue';
export default {
    components:{
    AConfirmation,
    ComponentDateModal,
    ASuccessWithoutReload,
},
    computed: mapState({
    //
    }),
    props:[
        'companyId'
    ],
    data: () => ({
        isPending:false,
        form:{
            hrdc_invoice:null,
            hrdc_invoice_sent_date:null,
            status:null,
            year:null,
            year_of_subscription:null,
        },
        optionHRDCStatus:[
            "Year 1",
            "Renewal - Year 2",
            "Renewal - Year 3",
        ],
        data:{
            status:null,
        },
        api:
        {
            isLoading : false,
            isError:false,
            error:null,
            url:null,
            isSuccesful:false,
            success:null,
        },
       
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="createCompanyHRDCByCompany") {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully create company HRDC";
            }
            if(resp.class==="createCompanyHRDCByBatch") {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully create company HRDC";

            }
            if(resp.class==="getCompanyHRDC") {
                this.data = resp.data;
                this.data.hrdc_invoice_sent_date = (this.data.hrdc_invoice_sent_date!=null)?this.convertTimeToDay(this.data.hrdc_invoice_sent_date):this.data.hrdc_invoice_sent_date;
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
    },
    methods: {
        fetch(){
        },
        
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            if(!Array.isArray(this.companyId)) {
                this.$api.fetch(this.create());
                return 1;
            }
            this.$api.fetch(this.createMultipleForm());

        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/hrdc/company/detail/"+this.companyId;
            tempApi.params = this.data;
            return tempApi;
        },
        createMultipleForm() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/hrdc/batch";
            let formData = new FormData;
            this.companyId.forEach((element)=>{
                formData.append('companyId[]',element.company_id);
            });
            formData.append('hrdc_invoice',this.data.hrdc_invoice);
            formData.append('hrdc_invoice_sent_date',this.data.hrdc_invoice_sent_date);
            formData.append('year',this.data.year);
            formData.append('year_of_subscription',this.data.year_of_subscription);
            tempApi.params = formData;
            return tempApi;
        },
        updateDataPaymentDate(data) {
            this.form.subscriptionPaymentDate = data;
        },
        convertTimeToDay(time) {
            return time.split(" ")[0];
        },
        closeModal() {
            this.$emit("close")
        },
        closeSuccessModal() {
            this.api.isSuccesful = false;
            this.$emit('finish');
        }
    }
}
</script>